import i18next from 'i18next';
import ICU from 'i18next-icu';
import vi from 'i18next-icu/locale-data/vi';
import en from 'i18next-icu/locale-data/en';
import systemEn from './en/system.json';
import systemVi from './vi/system.json';

i18next.use(new ICU({ localeData: [vi, en] }))
.init({
  interpolation: { escapeValue: false }, // React already does escaping

  lng: 'vi', // language to use
  fallbackLng: 'en',

  namespaces: ['system'],
  defaultNS: 'system',
  fallbackNS: 'system',

  resources: {
    en: {
      system: systemEn,
    },
    vi: {
      system: systemVi,
    },
  },
});

export default i18next;
