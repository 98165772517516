import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import LoginPage from './authentication/pages/LoginPage';

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/login/" />} />
      <Route exact path="/login/" component={LoginPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route path="/login/:refUrl" component={LoginPage} />
      <Route exact path="/logout/" component={LoginPage} />

      <Route path="*" render={() => <Redirect to="/login/" />} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;

